var utils ={
    /**
     * La liste des formats de fichiers accepté
     */
    formaFichierAccepte :["jpg","jpeg","png","pdf","xlsx","csv"],

    /**
     * La liste des formats de fichiers accepté
     */
    listeChampsParTable :[
      {table:"programmes",champ:"nom_programme",libelle:"Nom du programme"},
      {table:"programmes",champ:"description",libelle:"Description"},
      {table:"programmes",champ:"categorie_programme",libelle:"Catégorie du programme"},
      {table:"programmes",champ:"montant_initial_programme",libelle:"Montant initial du programme"},
      {table:"programmes",champ:"montant_revise",libelle:"Montant révisé"},
      {table:"programmes",champ:"date_debut",libelle:"Date de début"},
      {table:"programmes",champ:"annee_exercice",libelle:"Année d'exercice"},
      {table:"programmes",champ:"date_validation_programme",libelle:"Date de validation du programme"},
      {table:"programmes",champ:"date_lancement_programme",libelle:"Date de lancement du programme"},
      {table:"programmes",champ:"date_cloture_programme",libelle:"Date de clôture du programme"},
      {table:"programmes",champ:"date_transmission_rapport_cloture_programme",libelle:"Date de transmission du rapport de clôture du programme"},
      {table:"rubriques",champ:"libelle",libelle:"Libellé de la rubrique"},
      {table:"activites",champ:"libelle",libelle:"Libellé de l'activité"},
      {table:"activites_programmes",champ:"observation",libelle:"Observation"},
      {table:"activites_programmes",champ:"montant",libelle:"Montant de l'activité"},
      {table:"activites_programmes",champ:"montant_revise",libelle:"Montant révisé de l'activité"},
      {table:"marches",champ:"libelle",libelle:"Libellé du marché"},
      {table:"marches",champ:"numero_marche",libelle:"Numéro du marché"},
      {table:"marches",champ:"cout_marche",libelle:"Montant du marché"},


    ],

    /**
     * Pour la gestion des dates
     * La date de debut doit être inferieur à la date de fin
     * @param that l'element courant
     * @param dateDebut la valeur de la reference de la date de debut
     * @param dateFin  la valeur de la reference de la date de fin
     * @param origine l'origine de l'appelle de la fonction afin de determiner la signature du formulaire
     */
     getDate(that,dateDebut,dateFin,origine){

        const debut = that.$refs[dateDebut].value
        const fin = that.$refs[dateFin].value
        if (fin !=="" && debut ==="" ){
            if(origine ==="intellectuelle"){
                that.form.date_fin_execution=""
            }
            else if(origine ==="marche" || origine ==="programme"){
                that.form.date_fin=""
            }

            that.notif.message = "la date de fin doit être supérieure  a la date de debut"
            that.notif.type = "toast-danger"
            that.notif.activated = "activated"
            setTimeout(
                function() {
                    that.notif.activated = ""
                }.bind(that),
                5000)

        }
        else if(fin !=="" && debut > fin){
            if(origine ==="intellectuelle"){
                that.form.date_fin_execution=""
            }else{
                that.form.date_fin=""
            }
            that.notif.message = "la date de fin doit être supérieure a la date de debut"
            that.notif.type = "toast-danger"
            that.notif.activated = "activated"
            setTimeout(
                function() {
                    that.notif.activated = ""
                }.bind(that),
                5000)
        }

    },
    /**
     * Cette fontion permet de trouver le role l'utilisateur qui est connecté
     */
    getUserRole(){
        const userString = localStorage.getItem("user")
        const user = JSON.parse(userString)
        const userRole = user.user_role.split("_")[0]
        return userRole
    },
    getUserProfil(){
      const userString = localStorage.getItem("user")
      const user = JSON.parse(userString)
      const userProfil = user.user_profil.split("_")[1]
      return userProfil
  },

    /**
     * Renvoi le groupe du dernier valideur du decompte, DT,DCG DAF
     */
    getProfileDernierValideur(valideur){
      const splited = valideur.split("_")  
      if(splited[1] != "COMPTABILITE" && splited[1] != "DMC"){
        return splited[1]
      }else{
        return splited[0]
      }
    },
    getGroupeUtilisateur(){
        const userString = localStorage.getItem("user")
        const user = JSON.parse(userString)
        const userGroupe = user.user_role.split("_")[1]
        return userGroupe
    },
    /**
     * Renvoi le type du decompte
     */
    getTypeDecompte(type){
        const splited = type.split("_")
        return splited
    },
    /**
     * Cette fonction permet de rendre le format string du tableau contenant la liste des fichiers accepté
     */
    formaFichierAccepteToString(){
        let res = ""
        this.formaFichierAccepte.forEach(nom =>
        res +=" " +nom
        )
        return res

    },
    /**
     * La fonction permet de créer des espaces dans le visuel des nombre decimaux
     * @param nombre
     * @returns {string}
     */
    fixeDecimalTwo(nombre){
        var decimal =Number.parseFloat(nombre).toFixed(2)
        var parts = decimal.toString().split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        return parts.join(".")
    },
    /**
     * La fonction permet de créer des espaces deans les nombre entier
     * @param nombre
     * @returns {string}
     */
    espacingNumber(nombre){
        return nombre.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

}
export default utils
