<template>
  <form>
    <template v-for="(filtre,filkey) in filtres">
      <div class="form-row"
           :key="filkey">
        <label for="">Filtre n°{{filkey+1}}</label>
      </div>
      <div class="form-row"
           :key="'row'+filkey">
        <div class="form-group col-md-3">
          <select class="form-control"
                  v-model="filtre.libelle"
                  @change="addType(filtre)">
            <option value="">---Choisissez un champ---</option>
            <option v-for="(champ,chmpkey) in champs" 
                    :key="chmpkey"
                    :value="champ.valeur">{{champ.libelle}}</option>
          </select>
        </div>
        <div class="form-group col-md-3"
             v-if="filtre.libelle != ''">
          <select class="form-control"
                  v-model="filtre.nature"
                  v-if="filtre.type=='caractere'">
            <option value="">---Choisissez une option---</option>
            <option :value="'commence_par'">Commence par</option>
            <option :value="'termine_par'">Se termine par</option>
            <option :value="'contient'">Qui contient</option>
            <option :value="'egale'">Egale à</option>
          </select>
          <select class="form-control"
                  v-model="filtre.operation"
                  v-if="filtre.type=='date'">
            <option value="">---Choisissez une option---</option>
            <option :value="'superieur'">Supérieur</option>
            <option :value="'superieur_egale'">Supérieur ou égale</option>
            <option :value="'inferieur'">Inférieur</option>
            <option :value="'inferieur_egale'">Inférieur ou égale</option>
            <option :value="'egale'">Egale</option>
            <option :value="'interval'">Intervalle</option>
          </select>
          <select class="form-control"
                  v-model="filtre.nature"
                  v-if="filtre.type=='numeric'">
            <option value="">---Choisissez une option---</option>
            <option :value="'superieur'">Supérieur</option>
            <option :value="'superieur_egale'">Supérieur ou égale</option>
            <option :value="'inferieur'">Inférieur</option>
            <option :value="'inferieur_egale'">Inférieur ou égale</option>
            <option :value="'egale'">Egale</option>
          </select>
        </div>
        <div class="form-group col-md-3"
             v-if="filtre.type == 'date'">
          <select class="form-control"
                  v-model="filtre.periode">
            <option value="">---Choisissez une période---</option>
            <option :value="'jour'">Jour</option>
            <option :value="'mois'">Mois</option>
            <option :value="'annee'">Année</option>
          </select>
        </div>
        <div class="form-group col-md-3"
             v-if="filtre.nature != '' || (filtre.operation !='interval' && filtre.operation !='')">
          <input type="text"
                 class="form-control"
                 placeholder="Inscrivez une valeur"
                 v-model="filtre.valeur"
                 @change="saveFilter()">
        </div>
        <div class="form-group col-md-3"
             v-if="filtre.operation == 'interval'">
          <input type="text"
                 class="form-control"
                 placeholder="De:"
                 v-model="filtre.valeur"
                 @change="saveFilter()">
          <input type="text"
                 class="form-control"
                 placeholder="à:"
                 v-model="filtre.valeur1"
                 @change="saveFilter()">
        </div>
      </div>  
    </template>  
  </form>
</template>
<style>
.form-row input[type="text"],
.form-row input[type="number"],
.form-row input[type="date"],
.form-row input[type="email"],
label,
.form-row select,
button{
  font-size: 0.6em;
}

.form-row select option{
  font-size: 0.8em;
}
input,select,select option{
  height: fit-content;
}
</style>
<script>

export default ({
  name:"ListFilter",
  props:["champs"],
  data:()=>({
    filtres:[
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
    ],
  }),
  methods:{
    addType(filtre){
      filtre.nature=""
      filtre.operation=""
      filtre.valeur=""
      filtre.valeur1=""
      this.champs.forEach(champ => {
        if (champ.valeur == filtre.libelle) {
          filtre.type=champ.type
          filtre.table=champ.table
        }
      })
    },
    saveFilter(){
      this.$parent.filtre=[]
      this.filtres.forEach(element => {
        if (element.libelle !='' && (element.nature !='' || element.operation != '')) {
          this.$parent.filtre.push(element)
        }
      })
    }
  }
})
</script>
