<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Dettes bancaires></span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des dettes bancaires
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <router-link :to="{ name: 'AddLevee' }"
                   class="btn btn-blue"
                   v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')">
        Ajouter une dette bancaire
      </router-link>
      <div class="div_left mx-auto"
           v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal">Importer données historiques</button>
      </div>
      <div class="div_left">
        <router-link :to="{ path: '/filtre' }"
                     class="btn btn-blue">
          Filtrer
        </router-link>
      </div>
      <!-- <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div> -->
      <!-- <div class="div_left">
        <button class="btn btn-blue"
                @click="downloadExport(leveefonds.url)"
                download>
          Exporter
        </button>
      </div> -->
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row table_row my-4">
      <table class="table tablestyle_3 table-striped">
        <thead>
          <tr>
            <th class="th-blue" 
                scope="col">
              Prêteur
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:6%;">
              Nature de la dette
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Objet de la dette
            </th>
            <th class="th-blue text-right" 
                scope="col"
                style="width:auto;">
              Montant du prêt (FCFA)
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Date mise en place
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Date de fin remboursement
            </th>
            <!-- <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Nature du prêt 
            </th> -->
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Période de différé en assurance
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Période de différé en principal
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Période de différé en intérêt
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Période de différé en TOB
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Durée de remboursement 
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Montant du tirage (FCFA)
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Garantie accordée
            </th>
            <!-- <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Maturité
            </th> -->
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Nature garantie
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Garantie nécessaire
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Périodicité
            </th>
            <th class="th-blue" 
                scope="col"
                style="width:auto;">
              Taux d'intérêt
            </th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr>
            <td class="text-center"
                colspan="18">
              Chargement ...
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="long != 0">
          <tr v-for="(levee, lkey) in leveefond.donnees"
              :key="lkey"
              @click="launchDetails(levee.id)">
            <td>{{ levee.preteur.sigle }}</td>
            <td>{{ levee.nature_dette }}</td>
            <td>{{ levee.objet_dette }}</td>
            <td class="text-right">{{ Math.round(levee.montant_pret).toLocaleString() }}</td>
            <td>{{ levee.date_mise_place }}</td>
            <td>{{ levee.date_fin_remboursement }}</td>
            <!-- <td>{{ levee.nature_pret }}</td> -->
            <td>{{ levee.periode_differe_en_assurence }}</td>
            <td>{{ levee.periode_differe_en_principal }}</td>
            <td>{{ levee.periode_differe_en_interet }}</td>
            <td>{{ levee.periode_differe_en_tob }}</td>
            <td class="text-right">{{ levee.duree_remboursement }}</td>
            <td class="text-right">{{ Math.round(Number(levee.montant_tirages)).toLocaleString() }}</td>
            <td>{{ levee.garantie_accordee }}</td>
            <!-- <td>{{ levee.maturite }}</td> -->
            <td>{{ levee.nature_garantie }}</td>
            <td class="text-right">{{ Math.round(Number(levee.garantie_necessaire)).toLocaleString() }}</td>
            <td>{{ levee.periodicite }}</td>
            <td class="text-right">{{ Math.round(levee.taux_interet * 100) / 100 }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="18"
                class="text-center">
              Aucune levée de fonds enregistrée
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row pagination">
      <div v-if="leveefond.pagination"
           class="col-md-2 mx-auto text-center">
        <span v-if="leveefond.pagination.precedent"
              class="badge badge-pill badge-info"
              @click="index--, paging(leveefond.pagination.precedent)"> &#60; </span>
        {{ index }}
        <span v-if="leveefond.pagination.suivant"
              class="badge badge-pill badge-info"
              @click="index++, paging(leveefond.pagination.suivant)"> &#62; </span>
      </div>
    </div>
    <!-- Modal relevé bancaire-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données historiques
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close-import"
                    @click="viderFile()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for=""
                >Dettes</label
                >
                <div class="custom-file">
                  <label class="custom-file-label">
                    {{ inputFiles.donnees_histo }}
                  </label>

                  <input type="file"
                         name="etat_comptable"
                         class="custom-file-input"
                         id="donnees_histo"
                         ref="donnees_histo"
                         v-on:change="handleFileUpload('donnees_histo')"
                         accept=".xlsx,.csv" />
                  <span v-if="form.errors().has('donnees_histo')"
                        v-text="form.errors().get('donnees_histo')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('dettesFinal.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal"
                    @click="viderFile()">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.48em;
} */
.table_row{
  width:150%
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"
import ListFilter from "@/components/shared/Filter"

export default {
  name: "leveeDeFond",
  components:{
    ListFilter,
    Notif
  },
  data: () => ({
    leveeData: {
      libelle: "",
      order: "",
      tri: ""
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    filtre:[],
    chmp:[
      {
        valeur:"sigle",
        type:"caractere",
        table:"banques",
        libelle:"Prêteur",
      },
      {
        valeur:"nature_dette",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Nature de la dette",
      },
      {
        valeur:"objet_dette",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Objet de la dette",
      },
      {
        valeur:"montant_pret",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Montant du prêt",
      },
      {
        valeur:"date_fin_remboursement",
        type:"date",
        table:"levee_fonds",
        libelle:"Date fin remboursement",
      },
      {
        valeur:"nature_pret",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Nature du prêt",
      },
      {
        valeur:"date_mise_place",
        type:"date",
        table:"levee_fonds",
        libelle:"Date de mise en place",
      },
      {
        valeur:"periode_differe_en_assurance",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Période de différé en assurance",
      },
      {
        valeur:"periode_differe_en_principal",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Période de différé en principal",
      },
      {
        valeur:"periode_differe_en_interet",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Période de différé en interet",
      },
      {
        valeur:"periode_differe_en_tob",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Période de différé en TOB",
      },
      {
        valeur:"duree_remboursement",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Durée de remboursement",
      },
      {
        valeur:"montant_tirage",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Montant du tirage",
      },
      {
        valeur:"date_fin_remboursement",
        type:"date",
        table:"levee_fonds",
        libelle:"Date de fin de remboursement",
      },
      {
        valeur:"garantie_accordee",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Période de différé en interet",
      },
      {
        valeur:"maturite",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Maturité",
      },
      {
        valeur:"nature_garantie",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Nature garantie",
      },
      {
        valeur:"periodicite",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Périodicité",
      },
      {
        valeur:"taux_interet",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Taux d'interêt",
      },
    ],
    user:"",
    url:"",
    listExtensionFiles:"",
    uploadfile:"",
    inputFiles: {  
      donnees_histo:"" 
    },
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    index: 1,
    long: "",
    goodUrl: "",
    loaded: false
  }),
  watch: {
    leveefond() {
      if (this.leveefond.donnees) {
        this.loaded = true
        this.long = this.leveefond.donnees.length
        this.$refs["btn-close"].click()

      }
      //console.log(this.long)
    },
    successLevee(){
      if (this.successLevee) {
        this.notif.message = this.successLevee
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.setLeveeFond("")
            this.leveeFonds(null)
            this.$refs["btn-close-import"].click()
          }.bind(this),
          3000
        )
      }
    },
    errorsLevee(){
      if (this.errorsLevee) {
        this.notif.message = this.errorsLevee
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setError("")
            this.setFiles("")
            // this.$refs["modal_button"].click()
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
      }
    }
  },
  created() {
    localStorage.setItem("module","levee_fonds")
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.leveeFonds(null)
    this.listExtensionFiles = utils.formaFichierAccepte
  },
  computed: {
    ...mapGetters(["leveefond","files", "errorsMsg","successLevee","errorsLevee"])
  },
  methods: {
    ...mapActions(["leveeFonds","saveFile", "pageLevees","importDette"]),
    ...mapMutations(["setLeveeFond","setSuccess","setError","setFiles"]),
    // onFormChange() {
    //   this.form.validate()
    // },
    launchDetails(id) {
      this.$router.push({ name: "DetailLevee", params: { id: id } })
    },
    paging(url) {
      if (this.index == 0) {
        this.index = 1
      }
      this.goodUrl = url.split("/")
      //console.log(this.goodUrl)
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.pageLevees(url)
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.setLeveeFond("")
      this.leveeFonds(this.filtre)
    },
    viderFile(){
      this.inputFiles.donnees_histo=''
      this.setFiles('')
      this.$refs["donnees_histo"].value=""
    },
    restoring() {
      this.loaded = false
      this.long = null
      this.leveeData = { libelle: "", order: "", tri: "" }
      this.leveeFonds(null)
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]

      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    submit() {
      this.importDette({url:this.url})
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>
